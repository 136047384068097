import * as _sister4 from "sister";
var _sister3 = "default" in _sister4 ? _sister4.default : _sister4;
import _YouTubePlayer3 from "./YouTubePlayer";
import _loadYouTubeIframeApi3 from "./loadYouTubeIframeApi";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _sister = _sister3;
var _sister2 = _interopRequireDefault(_sister);
var _YouTubePlayer = _YouTubePlayer3;
var _YouTubePlayer2 = _interopRequireDefault(_YouTubePlayer);
var _loadYouTubeIframeApi = _loadYouTubeIframeApi3;
var _loadYouTubeIframeApi2 = _interopRequireDefault(_loadYouTubeIframeApi);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

/**
 * @typedef YT.Player
 * @see https://developers.google.com/youtube/iframe_api_reference
 */

/**
 * @see https://developers.google.com/youtube/iframe_api_reference#Loading_a_Video_Player
 */
let youtubeIframeAPI;

/**
 * A factory function used to produce an instance of YT.Player and queue function calls and proxy events of the resulting object.
 *
 * @param maybeElementId Either An existing YT.Player instance,
 * the DOM element or the id of the HTML element where the API will insert an <iframe>.
 * @param options See `options` (Ignored when using an existing YT.Player instance).
 * @param strictState A flag designating whether or not to wait for
 * an acceptable state when calling supported functions. Default: `false`.
 * See `FunctionStateMap.js` for supported functions and acceptable states.
 */

exports.default = (maybeElementId, options = {}, strictState = false) => {
  const emitter = (0, _sister2.default)();
  if (!youtubeIframeAPI) {
    youtubeIframeAPI = (0, _loadYouTubeIframeApi2.default)(emitter);
  }
  if (options.events) {
    throw new Error("Event handlers cannot be overwritten.");
  }

  // eslint-disable-next-line unicorn/prefer-query-selector
  if (typeof maybeElementId === "string" && !document.getElementById(maybeElementId)) {
    throw new Error("Element \"" + maybeElementId + "\" does not exist.");
  }
  options.events = _YouTubePlayer2.default.proxyEvents(emitter);
  const playerAPIReady = new Promise(resolve => {
    if (typeof maybeElementId === "object" && maybeElementId.playVideo instanceof Function) {
      const player = maybeElementId;
      resolve(player);
    } else {
      // asume maybeElementId can be rendered inside
      // eslint-disable-next-line promise/catch-or-return
      youtubeIframeAPI.then(YT => {
        // eslint-disable-line promise/prefer-await-to-then
        const player = new YT.Player(maybeElementId, options);
        emitter.on("ready", () => {
          resolve(player);
        });
        return null;
      });
    }
  });
  const playerApi = _YouTubePlayer2.default.promisifyPlayer(playerAPIReady, strictState);
  playerApi.on = emitter.on;
  playerApi.off = emitter.off;
  return playerApi;
};
exports = exports["default"];
export default exports;
export const __esModule = exports.__esModule;