import _PlayerStates3 from "./constants/PlayerStates";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _PlayerStates = _PlayerStates3;
var _PlayerStates2 = _interopRequireDefault(_PlayerStates);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
exports.default = {
  pauseVideo: {
    acceptableStates: [_PlayerStates2.default.ENDED, _PlayerStates2.default.PAUSED],
    stateChangeRequired: false
  },
  playVideo: {
    acceptableStates: [_PlayerStates2.default.ENDED, _PlayerStates2.default.PLAYING],
    stateChangeRequired: false
  },
  seekTo: {
    acceptableStates: [_PlayerStates2.default.ENDED, _PlayerStates2.default.PLAYING, _PlayerStates2.default.PAUSED],
    stateChangeRequired: true,
    // TRICKY: `seekTo` may not cause a state change if no buffering is
    // required.
    // eslint-disable-next-line unicorn/numeric-separators-style
    timeout: 3000
  }
};
exports = exports["default"];
export default exports;