import * as _loadScript4 from "load-script";
var _loadScript3 = "default" in _loadScript4 ? _loadScript4.default : _loadScript4;
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _loadScript = _loadScript3;
var _loadScript2 = _interopRequireDefault(_loadScript);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
exports.default = emitter => {
  /**
   * A promise that is resolved when window.onYouTubeIframeAPIReady is called.
   * The promise is resolved with a reference to window.YT object.
   */
  const iframeAPIReady = new Promise(resolve => {
    if (window.YT && window.YT.Player && window.YT.Player instanceof Function) {
      resolve(window.YT);
      return;
    } else {
      const protocol = window.location.protocol === "http:" ? "http:" : "https:";
      (0, _loadScript2.default)(protocol + "//www.youtube.com/iframe_api", error => {
        if (error) {
          emitter.trigger("error", error);
        }
      });
    }
    const previous = window.onYouTubeIframeAPIReady;

    // The API will call this function when page has finished downloading
    // the JavaScript for the player API.
    window.onYouTubeIframeAPIReady = () => {
      if (previous) {
        previous();
      }
      resolve(window.YT);
    };
  });
  return iframeAPIReady;
};
exports = exports["default"];
export default exports;